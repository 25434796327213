/*---------------------------------------
      enrollForm             
  -----------------------------------------*/

.is-invalid {
  border: 1px solid red;
}

#enrollForm {
  background: #f9f9f9;
  padding-top: 100px;
}

#enrollForm h2 {
  /* color: #ffffff; */
  color: #353535;
}

#enrollForm .section-title {
  padding-bottom: 20px;
}

#enrollForm h2 > small,
#enrollForm p,
#enrollForm a {
  color: #353535;
}

#enrollForm-form {
  padding: 1em 0;
}

#enrollForm-form .col-md-12,
#enrollForm-form .col-md-4 {
  padding-left: 0;
}

#enrollForm-form .form-control {
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  margin: 10px 0;
}

#enrollForm-form input {
  height: 50px;
}
#enrollForm-form select {
  height: 50px;
}

#enrollForm-form input[type="submit"] {
  border-radius: 50px;
  border: 1px solid transparent;
  background: dodgerblue;
  color: #353535;
}

#enrollForm-form input[type="submit"]:hover {
  border-color: #ffffff;
  color: #353535;
}

@media only screen and (max-width: 992px) {
  section,
  footer {
    padding: 60px 0;
  }

  .footer-info {
    margin-bottom: 50px;
  }

  .contact-image {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 580px) {
  h2 {
    font-size: 1.8em;
  }

  .contact-image {
    margin-top: 0;
  }
}
